<template>
	<div class="box">
		<div class="title_box flex">
			<Title title="修改资料" />
			<button class="save" @click="upDateFn('个人信息')">保存</button>
		</div>
		<!-- 修改个人信息 -->
		<div class="ct">
			<div class="input_box flex">
				<div class="input_title_box">
					<p>用户名：</p>
				</div>
				<el-input v-model="nickname"></el-input>
			</div>
			<div class="input_box flex">
				<div class="input_title_box">
					<p>手机号：</p>
				</div>
				<el-input v-model="mobile"></el-input>
			</div>
			<div class="input_box flex">
				<div class="input_title_box">
					<p>QQ：</p>
				</div>
				<el-input v-model="qq"></el-input>
			</div>

			<div class="input_box flex">
				<div class="input_title_box">
					<p>电子邮箱：</p>
				</div>
				<el-input v-model="email"></el-input>
			</div>
		</div>
		<!-- 修改密码 -->
		<div class="title_box flex">
			<Title title="修改资料" />
			<button class="save" @click="editPwd()">保存</button>
		</div>
		<div class="ct">
			<div class="input_box flex">
				<div class="input_title_box">
					<p>原密码：</p>
				</div>
				<el-input v-model="password" show-password></el-input>
			</div>
			<div class="input_box flex">
				<div class="input_title_box">
					<p>新密码：</p>
				</div>
				<el-input v-model="newPassword" show-password></el-input>
			</div>
			<div class="input_box flex">
				<div class="input_title_box">
					<p>确认密码：</p>
				</div>
				<el-input v-model="repeatpassword" show-password></el-input>
			</div>
		</div>
	</div>
</template>

<script>
import Title from '../../components/UserInfoTitle.vue'
export default {
	components: {
		Title,
	},
	mounted() {

		if (localStorage.getItem('user_info')) {
			let user_info = JSON.parse(localStorage.getItem('user_info'))
			// console.log('mountedUser:', user_info);
			this.nickname = user_info.username
			this.mobile = user_info.mobile
			this.qq = user_info.qq
			this.email = user_info.email
		}
		// console.log('user_infoMouted:', this.$user_info);
	},
	data() {
		return {
			nickname: '',
			mobile: '',
			qq: '',
			email: '',
			password: '',
			newPassword: '',
			repeatpassword: '',
		}
	},
	methods: {
		upDateFn() {
			this.curlPost('/api/user/edit', {
				nickname: this.nickname,
				mobile: this.mobile,
				qq: this.qq,
				email: this.email,
			}).then(res => {
				if (res.data.code) {
					this.$message({
						message: '修改成功',
						type: 'success'
					})
					this.getUserInfo()


				} else {
					this.$message({
						message: res.data.msg,
						type: 'error'
					})
				}
				setTimeout(() => {
					this.$router.push({
						path: '/user/index',
					})
				}, 1000)
			})
		},
		// 获取用户信息
		getUserInfo() {
			this.curlGet('/api/user/info').then((res) => {
				if (res.data.code) {
					this.$user_info = res.data.data
					// console.log('user_info:', this.$user_info);
					this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage

				}
			})
		},
		editPwd() {
			this.curlPost('/api/user/edit_pwd', {
				password: this.password,
				newpassword: this.newPassword,
				repeatpassword: this.newPassword,
			}).then(res => {
				if (res.data.code) {
					this.$message({ message: '修改成功', type: 'success' })
					setTimeout(() => {
						this.$router.push({
							path: '/user/index',
						})
					}, 1000)
				} else {
					return this.$message({ message: res.data.msg, type: 'error' })
				}

				// this.getUserInfo()
			})
		}
	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/Resource';
</style>